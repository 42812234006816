import logo from './logo.svg';
import './App.css';
import { HashRouter, BrowserRouter,  Route, Routes } from 'react-router-dom';
import HomeScreen from './screens/HomeScreen';
import InviteDetail from './screens/InviteDetail';
import VotingDetailScreen from './screens/votingDetailsScreen';
import PrivacyPolicyScreen from './screens/privacyPolicyScreen';
import VerifyEmailScreen from './screens/verifyEmailScreen';


function App() {
  return (
    <div className="App">
    <HashRouter>
      <div>
        
          <Routes>
          <Route path="/" element={<HomeScreen />} exact />
          <Route path="/privacy-policy" element={<PrivacyPolicyScreen />} exact />
          <Route path="/invite/:id" element={<InviteDetail/>}/>
          <Route path="/voting/:id" element={<VotingDetailScreen/>}/>
          <Route path="/verify/:id" element={<VerifyEmailScreen/>}/>


          
          <Route component={Error}/>
         </Routes>
      </div> 
    </HashRouter>
  
  </div>
  );
}

export default App;
