import React from 'react';
// import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom"
import { getInviteDetails } from "../api-service/InviteDetailsService";
import './HomeScreen.css';

import DocumentMeta from 'react-document-meta';
function HomeScreen() {

    const navigate = useNavigate()

    // const redirectToDetails = () => {
    //     console.log("MOVE TO ANOTHE")
    //     navigate("/invite")
    // }

    const [metInfo, setMetaInfo] = React.useState({});
    // let meta = {
    //     title: 'Some Meta Title',
    //     description: 'I am a description, and I can create multiple tags',
    //     canonical: 'http://example.com/path/to/page',
    //     meta: {
    //       charset: 'utf-8',
    //       name: {
    //         keywords: 'react,meta,document,html,tags'
    //       }
    //     }
    //   };
    // let meta;
    // React.useEffect(() => {

    //     getInviteDetails().then((data) => {
    //         console.log(data.data.status)
    //         if (data.data.status === 200) {
    //             setMetaInfo(data.data.data.data[0]);
    //         }


    //     })



    // }, []);

    return (
        <div>

            <header className='main-header'>
                <div className="menu-wrap">

                    <a href='https://apis.circlesappdev.cloudns.ph/api-docs/' target='_blank' className="logo-wrap">
                        <img className="logo" src="https://res.cloudinary.com/enforce-solutions/image/upload/v1655638868/logo_temp_ux0hs9.png" alt="Logo" />
                    </a>



                    <input type='checkbox' id='nav-tog' />


                    <label for='nav-tog' className="drop-trigger">

                        <span className="btn-bar top"></span>
                        <span className='btn-bar middle'></span>
                        <span className='btn-bar bottom'></span>
                    </label>


                    <nav className="main-nav">

                        <label for='nav-tog' className='nav-revert'></label>


                        <a className="nav-item">
                            <span> About </span>
                        </a>
                        <a className="nav-item">
                            <span> Faq </span>
                        </a>
                        <a className="nav-item">
                            <span> How it Works </span>
                        </a>
                    </nav>
                </div>
            </header>

            <div className='firstContainer'>
                <div className='container-row' >
                        <h3 className="firstTitle">
                           Smart Way to Manage Inviations
                        </h3>
                </div>
            </div>

            <div className='imgContainer' >


            <img src="https://res.cloudinary.com/enforce-solutions/image/upload/v1653630885/bannerImage-removebg_knelwm.png" alt="Logo" />


            </div>


            





            <div className='footerCard'>
                    <div className='footerContainer'>
                        <div className='footer-row'>

                            <div className='footer-col'>
                                <p > Circles Pro </p>
                                <p>A powerful Invitation management app that helps you send,
                                    manage and receive invitations on the go. Free RSVP online
                                    app for your events. You name the event and we got it covered.</p>
                            </div>

                            <div className='footer-col'>
                                <p >Download the App </p>

                                <div className='googlePlayLogo'   >
                                   
                                    <img src="https://e.hubapp.net/images/google-play.svg" alt="Google Play" width="250" /> 
                             
                                </div>

                            </div>

                        </div>


                    </div>
            </div>


            




        </div>
    )
}

export default HomeScreen;