import React from 'react';
import './verifyEmail.css';
import { useParams } from 'react-router-dom';
import { Label, TextInput, Checkbox, Button, Tabs, Navbar, Dropdown, Avatar, Spinner } from 'flowbite-react';
import { updateUserPhone } from "../../api-service/InviteDetailsService";
import { useNavigate } from "react-router-dom";

function VerifyEmailScreen() {


  const { id } = useParams();

  const navigate = useNavigate()

  const [userid, setUserId] = React.useState("");

  const [userPhone, setuserPhone] = React.useState("");

  const [userConfirmPhone, setConfirmPhone] = React.useState("");

  React.useEffect(() => {

    setUserId(id);
  }, []);


  const updateUserPhoneNum = () => {
    console.log(userPhone, userConfirmPhone)
    if (userPhone != userConfirmPhone) {
      alert('Phone Numbers Don not match')
    }

    updateUserPhone(userid, userPhone).then((res) => {
      console.log(res);
      if (res) {
        navigate('/');
      }

    }).catch((err) => {
      console.log(err);
    })

  }



  return (
    <div className="p-4" >

      <form className="flex flex-col gap-4">

        <div>
          <div className="mb-2 block">
            <Label

              value="Your Phone Number"
            />
          </div>
          <TextInput
            id="password2"
            type="tel"
            required={true}
            shadow={true}
            value={userPhone}
            onChange={(val) => { setuserPhone(val.target.value) }}
          />
        </div>
        <div>
          <div className="mb-2 block">
            <Label

              value="Confirm Phone Number"
            />
          </div>
          <TextInput
            id="repeat-password"
            type="tel"
            required={true}
            shadow={true}
            value={userConfirmPhone}
            onChange={(val) => { setConfirmPhone(val.target.value) }}
          />
        </div>
        <div className="flex items-center gap-2">
          <Checkbox id="agree" />
          <Label htmlFor="agree">
            I agree with the{' '}
            <a
              href="/forms"
              className="text-blue-600 hover:underline dark:text-blue-500"
            >
              terms and conditions
            </a>
          </Label>
        </div>
        <Button type="submit" onClick={() => { updateUserPhoneNum() }} >
          Verify Phone Number
        </Button>
      </form>



    </div>
  )
}

export default VerifyEmailScreen