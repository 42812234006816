import React , { useState } from 'react';
import { useParams } from 'react-router-dom'
import { getInviteDetails } from "../api-service/InviteDetailsService";
import './inviteDetails.css';

function InviteDetail() {


    const { id } = useParams();
    const [eveSlug,setEveSlug] = useState("");
    let url;


    const [metInfo, setMetaInfo] = React.useState({});
    const [locText ,setLocationText] =  React.useState('');
    const [width, setWidth] = React.useState(window.innerWidth);
    const [popupMsg,setpopupMsg] = React.useState(false);


    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    const dismissPopup  = () =>{
        setpopupMsg(false)
    } 


    const redirectToApp = () => {
        window.location.replace('https://play.google.com/store/apps/details?id=com.enforce.circles')
    }

    React.useEffect(() => {
        getInviteDetails(id).then((data) => {
            console.log(data.data.status)
            if (data.data.status === 200) {
                
                setMetaInfo(data.data.data.data[0]);

                if(data.data.data.data[0].eventSlug != null) {
                    setEveSlug(data.data.data.data[0].eventSlug);
                }


            }


            
            url = `https://maps.google.com/maps?q=${metInfo.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`
            setLocationText(url)

            if(width <= 768)  {
                setpopupMsg(true)
               }
    
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
          

        })
    }, []);


    return (

        <div className='mainDiv' >
            <div className='rootDiv'>
                <div className='cardDiv'>
                    <div className='mainCardDiv'>
                        <div className='innerContent' >
                            <div className='cardText'>
                                {metInfo.invitedBy} has invited you to a private event and awaits your response.
                            </div>
                            <div className='eveName'>
                                   { metInfo.title  } 
                            </div>
                            <div className='eveName'>
                                On  {  new Date(metInfo.startDate).toLocaleString() } 
                            </div>
                            <div className='contentText'>
                                At { metInfo.location  }

                            </div>
                            <div className='divAddrs'>

                             
                                <div className="mapouter">
                                    <div className="gmap_canvas">
                                        <iframe width="500" height="200" id="gmap_canvas" src={locText} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                                    </div>
                                </div>

                            </div>

                            <div className='contentText'>
                                Reply if you attend by clicking the buttons below. If you are not sure yet, click the orange “maybe”
                            </div>
                            <div className='divCTAbtns'>
                                <div className='btn'>
                                <a 
                                className='appLink'
                                    onClick={()=>redirectToApp()}
                                  > Yes </a>
                                </div>
                                <div className='btn' onClick={()=>redirectToApp()}>
                                        No
                                </div>
                                <div className='btn' onClick={()=>redirectToApp()} >
                                        May be
                                </div>
                            </div>



                        </div>

                    </div>
                </div>


{

popupMsg === true  &&

<div className="mobileOne" >
    <div className="mobcard">
     <span onClick={()=>dismissPopup()} className='popupCancel'> X </span>
        
        <p className='appPopupText'> 
            Its seems you are viewing this on Mobile Browser
        </p>


        <div className={`btnCon appPopupbtn`}>
            <div className='textItemStyle'>
                

                <a 
            className='appLink'
            href={`intent://invite,${eveSlug}/#Intent;scheme=zxinger;package=com.enforce.circles;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.enforce.circles;end`}>
             
                 Open App
                 </a>


            </div>
        </div>

    </div>
</div>






}

                <div className='footerCard'>
                    <div className='footerContainer'>
                        <div className='footer-row'>

                            <div className='footer-col'>
                                <p > Circles Pro </p>
                                <p>A powerful Invitation management app that helps you send,
                                    manage and receive invitations on the go. Free RSVP online
                                    app for your events. You name the event and we got it covered.</p>
                            </div>

                            <div className='footer-col'>
                                <p >Download the App </p>

                                <div className='googlePlayLogo'>
                                   
                                    <img src="https://e.hubapp.net/images/google-play.svg" alt="Google Play" width="250" /> 
                             
                                </div>

                            </div>

                        </div>


                    </div>
                </div>


            </div>
        </div>
    )
}

export default InviteDetail;