import axios from 'axios';
import { API_URL } from '../constants';



export const getInviteDetails = (slugValue) =>{
    let config = {
        method: 'get',
        url: `${API_URL}/event?eventSlug=${slugValue}&populate=eventType,user`,
        headers: {
          'Content-Type': 'application/json',
        }
      };
      //@ts-ignore
      return axios(config);
}

export const getInviteVotingDetails = (slugValue) =>{
  let config = {
      method: 'get',
      url: `${API_URL}/eventVoting?votingSlug=${slugValue}&populate=eventType,user`,
      headers: {
        'Content-Type': 'application/json',
      }
    };
    //@ts-ignore
    return axios(config);
}

export const updateUserPhone = (userId,phone) => {


  var data = JSON.stringify({
    "phone": phone,
    "code" : Math.random()
  });
  

  var config = {
    method: 'put',
    url: `${API_URL}/user/updatePhone/${userId}`,
    headers: { 
      'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6IjUwMTk4MDY1MyIsImlkIjoiNjI0NDJjN2VmMzEwZDAzNWQ2ZWU0ODM4IiwiaWF0IjoxNjUwODYzMzQ0LCJleHAiOjE2NTA5NDk3NDR9.xF06eu9l7xhojrk4e2_YXSTlH9VkhivtPU44VPzlvVs', 
      'Content-Type': 'application/json'
    },
    data : data
  };
  //@ts-ignore
  return axios(config);
}