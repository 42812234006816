import React from 'react';
import './votingDetails.css';
import { useParams } from 'react-router-dom';
import { getInviteVotingDetails } from "../../api-service/InviteDetailsService";

const VotingDetailScreen = () => {


    const { id } = useParams();
    let url;


    const [metInfo, setMetaInfo] = React.useState({});
    const [locText, setLocationText] = React.useState('');
    const [eveSlug,setEveSlug] = React.useState("");
    const [popupMsg,setpopupMsg] = React.useState(false);


    const [width, setWidth] = React.useState(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        getInviteVotingDetails(id).then((data) => {
            console.log(data.data.status)
            if (data.data.status === 200) {
                setMetaInfo(data.data.data.data[0]);
            }
            if(data.data.data.data[0].votingSlug != null) {
                setEveSlug(data.data.data.data[0].votingSlug);
            }

            // url = `https://maps.google.com/maps?q=Juhu Tara Road&t=&z=13&ie=UTF8&iwloc=&output=embed`
            //  setLocationText(url)
        })
        if(width <= 768)  {
            setpopupMsg(true)
           }

        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }

     




    }, []);

    const dismissPopup  = () =>{
        setpopupMsg(false)
    } 

    const onChangeValue = (event) => {
        console.log(event.target.value)
    }

    const redirectToApp = () => {
        window.location.replace('https://play.google.com/store/apps/details?id=com.enforce.circles')
    }


    return (

        <div className='mainDiv' >
            <div className='rootDiv'>


                <div className='cardDiv'>
                    <div className='mainCardDiv'>
                        <div className='innerContent' >

                        <div className='textItemStyle'>
                                {metInfo.invitedBy} has invited you to Select a Date/Time and Venue for Upcoming event.
                        </div>


                            <div className='eveName'>
                                {metInfo.title}
                            </div>

                            {
                                metInfo.bannerUrl &&

                                <div className="backgroundPicDiv">
                                    <img src={metInfo.bannerUrl.url} />
                                </div>

                            }

                            <div className='divDateOpsMain'>
                                <h3 className='textLabelStyle'> Date & Time </h3>

                                {

                                    metInfo.dateOptions &&
                                    metInfo.dateOptions.map((el) => {

                                        return (

                                            <div key={el._id} className='dateItem'>
                                                <div className='dateContent'>
                                                    <div onChange={onChangeValue}  >
                                                        <div className='checkContainer'>
                                                            <input type={'radio'} value={new Date(el.startDate)} name="dateOption" />
                                                        </div>
                                                    </div>

                                                    <div className='dataContainer' >

                                                        <div className='textItemStyle'>
                                                            {new Date(el.startDate).getDate()


                                                                + "/"
                                                                + (new Date(el.startDate).getMonth() + 1) + "/"
                                                                + new Date(el.startDate).getFullYear() + " - "
                                                                + new Date(el.startDate).getHours() + ":"
                                                                + new Date(el.startDate).getMinutes()
                                                            }
                                                        </div>
                                                        <div className='voteDetails'>
                                                            Votes 0/ 4
                                                        </div>


                                                    </div>


                                                </div>
                                            </div>

                                        )

                                    })

                                }


                            </div>


                            <div className='divDateOpsMain'>
                                <h3 className='textLabelStyle'> Address/Event Venue Options </h3>

                                {

                                    metInfo.addressOptions &&
                                    metInfo.addressOptions.map((el) => {

                                        return (
                                            <div key={el._id} className='dateItem'>


                                                <div className='dateContent'>
                                                    <div onChange={onChangeValue}  >
                                                        <div className='checkContainer'>
                                                            <input type={'radio'} value={new Date(el.location)} name="addressOption" />
                                                        </div>
                                                    </div>
                                                    <div className='dataContainer' >
                                                        <div className='textItemStyle'>
                                                            {
                                                                el.location
                                                            }
                                                        </div>
                                                        <div className='voteDetails'>
                                                            Votes 0/ 4
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                }


                            </div>


                            <div className='btnCon'>
                                <div  onClick={()=> redirectToApp()} className='textItemStyle'>
                                    Continue
                                </div>
                            </div>


                        </div>

                    </div>
                </div>

                {

                    popupMsg === true  &&


                    <div className="mobileOne" >


                        <div className="mobcard">

                                        <span onClick={()=>dismissPopup()} className='popupCancel'> X </span>
                       


                            
                            <p className='appPopupText'> 
                                Its seems you are viewing this on Mobile Browser
                            </p>


                            <div className={`btnCon appPopupbtn`}>
                                <div className='textItemStyle'>
                                    

                                    <a 
                                className='appLink'
                                href={`intent://voting,${eveSlug}/#Intent;scheme=zxinger;package=com.enforce.circles;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.enforce.circles;end`}>
                                 
                                     Open App
                                     </a>


                                </div>
                            </div>


                            {/* <p class="color-white margin-bottom-5"></p> */}
                            {/* <button type="submit" (click)="redirectToPlaystore()" class="btn btn-primary font-size-15 padding-7">Get Refr
                            App</button> */}
                        </div>
                    </div>






                }




                <div className='footerCard'>
                    <div className='footerContainer'>
                        <div className='footer-row'>

                            <div className='footer-col'>
                                <p > Circles Pro </p>
                                <p>A powerful Invitation management app that helps you send,
                                    manage and receive invitations on the go. Free RSVP online
                                    app for your events. You name the event and we got it covered.</p>
                            </div>

                            <div className='footer-col'>
                                <p >Download the App </p>

                                <div className='googlePlayLogo'   >

                                    <img src="https://e.hubapp.net/images/google-play.svg" alt="Google Play" width="250" />

                                </div>

                            </div>

                        </div>


                    </div>
                </div>

            </div>
        </div>
    )
}

export default VotingDetailScreen;